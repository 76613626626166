import React, { useContext } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { UserContext } from './context/UserContextProvider';
import Dashboard from './pages/Dashboard';
import SampleDashboard from './pages/SampleDashboard';
import InstructionsPDF from './pages/InstructionsPDF';
import PatientsPDF from './pages/PatientsPDF';
import EnterPatients from './pages/EnterPatients';
import TestPDF from './pages/TestPDF';
import EnterOrders from './pages/EnterOrders';
import RecordResponses from './pages/RecordResponses';    
import SubmitAffirmation from './pages/SubmitAffirmation';
import ViewResults from './pages/ViewResults';
import Login from './pages/Login';
import Header from './Header';
import Footer from './Footer';
import TimeOutModal from './pages/tools/TimeOutModal';
import ViewHistoricalResults from "./pages/ViewHistoricalResults";
// import HelpWiki from './pages/HelpWiki';

function App() {

  const contextData = useContext(UserContext)

  function PrivateRoute({ component: Component, ...rest }) {
    // let unAuthRedirected = false;

    // useEffect(() => {
    //   if (unAuthRedirected) {
    //     contextData.notLoggedIn();
    //   }
    // }, [unAuthRedirected])
  
    return (
      <Route
        {...rest}
        render={props => {
          if  (contextData.isLoggedIn) {
            return <Component {...props}/>
          } else {
            
            // unAuthRedirected = true;
            // updating this value triggers useEffect hook to update
            // the login error message on Login.jsx
            
            return <Redirect to={{
                pathname: "/",
                state: {
                  from: props.location
                }
              }}
              />
            }}}
      />
    );
  }

  return (
    <div className="App">
      <Router>
        <Header />
          <Switch>
            {/* <Route path="/login" component={Login}/> */}
            <PrivateRoute path="/instructionspdf" component={InstructionsPDF} /> 
            <PrivateRoute path="/patientspdf" component={PatientsPDF} /> 
            <PrivateRoute path="/enterpatients" component={EnterPatients} /> 
            <PrivateRoute path="/testpdf" component={TestPDF} />
            <PrivateRoute path="/enterorders" component={EnterOrders} />
            <PrivateRoute path="/recordresponses" component={RecordResponses} /> 
            <PrivateRoute path="/submitaffirmation" component={SubmitAffirmation} />
            <PrivateRoute path="/viewresults" component={ViewResults} />
            <PrivateRoute path="/viewhistoricalresults" component={ViewHistoricalResults} />
            <PrivateRoute path="/sampledashboard" component={SampleDashboard} />
            <PrivateRoute path="/dashboard" component={Dashboard} /> 
            {/* <PrivateRoute path="/helpwiki" component={HelpWiki} />  */}
            <Route path="/">
              {contextData.isLoggedIn ? <Dashboard/> : <Login/>}
            </Route>
          </Switch>
          <TimeOutModal />
          {contextData.isLoggedIn ? <Footer /> : ''}
      </Router>
    </div>
  );
}

export default App;