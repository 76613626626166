
// This is the API configuration file for the Survey/Test client

export const specificApiConfig = {
    
    // SITE TEST CLIENT APIs
    siteTestLogin: "/api/v1/siteTestClient/siteTestLogin",
    // takes {"access-code": "string", "email": "string"}
    
    getCurrentSiteTestStep: "/api/v1/siteTestClient/getCurrentSiteTestStep",
    // takes {"access-code": "string"}
    
    updateCurrentSiteTestStep: "/api/v1/siteTestClient/updateCurrentSiteTestStep",
    // takes {"access-code": "string", "ui-step": "string"}
    
    // Valid steps are "NOT_STARTED", "PATIENT_PDF_REQUESTED", "ENTER_PATIENTS", "ORDER_PDF_REQUESTED", 
    // "ENTER_ORDERS", "RECORD_RESPONSES", "ATTESTATION", "SCORE_RESPONSES".
    
    getSiteTestPatients: "/api/v1/siteTestClient/getSiteTestPatients",
    // takes {"access-code": "string"}
    
    getSiteTestOrders: "/api/v1/siteTestClient/getSiteTestOrders",
    // takes {"access-code": "string"}
    
    recordTestResponse: "/api/v1/siteTestClient/recordTestResponse",
    // takes {"access-code": "string", test-response: string}
    
    getSiteTestScoreSheet: "/api/v1/siteTestClient/getSiteTestScoreSheet",
    // takes {"access-code": "string", test-response: string}

    setAttestationComplete: "/api/v1/siteTestClient/setAttestationComplete",
    // takes {"access-code": "string"}

    getScoreByAccessCode: "/api/v1/siteTestClient/getScoreByAccessCode",
    // takes {"access-code": "string"}

    getAllScoresByAccessCode: "/api/v1/siteTestClient/getAllScoresByAccessCode",
    // takes {"access-code": "string"}

    getSiteTestHistory: "/api/v1/siteTestClient/getSiteTestHistory",
    // takes {"access-code": "string"}

    getAmbulatoryAnalysisByAccessCode: "/api/v1/siteTestClient/getAmbulatoryAnalysisByAccessCode",
    // takes {"accesscode": "string"}
    
    exchangeTemporaryAccessCode: "/api/v1/siteTestClient/exchangeTemporaryAccessCode",
    // takes {"access-code": "string"}
    
    getClockTimeoutStatus: "/api/v1/siteTestClient/getClockTimeoutStatus"
    // takes {"access-code": "string"}
}