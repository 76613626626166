import React, {useContext} from 'react';
import { LinkContainer } from 'react-router-bootstrap';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
// import NavDropdown from 'react-bootstrap/NavDropdown'
import { UserContext } from './context/UserContextProvider';
import Clock from './pages/tools/Clock'

// TODO: LeapFrog Help Desk Link should be loaded from config, ideally

const Header = () => {

const { logoutUser, isLoggedIn, currentStep, clientUrlRoot, testUnderway, isLeapFrogTest, isSampleTest} = useContext(UserContext); 

const handleLogout = () => {
  logoutUser();
} 

    return ( 
    <>
      <style type="text/css">
        {`
        .navbar-ccts {
          background-color: #363237;
        }

        .navbar-brand {
          color: #fefeff;
          font-weight: 300;
        }

        .navbar-toggler-icon {
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255,255,255, .80)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
        }

        .navbar .nav-link {
          color: #ffffff;
          font-size: 1rem;
          font-weight: 300;
        }
        
        .navbar .nav-link:hover {
          color: #cccccc;
        }

        `}
      </style>

      <Navbar collapseOnSelect expand="md" variant="ccts">
        <LinkContainer to={isSampleTest !== true ? "/dashboard" : currentStep}><Navbar.Brand href="/">
          {isLoggedIn !== true && <img
              src="/UlogoHv1_RGB_40x142.jpeg"
              height="40"
              width="142"
              className="d-inline-block align-top"
              alt="University of Utah"
              style={{ marginRight: '10px', marginTop: '-5px', marginBottom: '-5px' }}
          /> }
          CPOE/EHR Evaluation Tool</Navbar.Brand></LinkContainer>
        <Navbar.Toggle />
        <Navbar.Collapse variant="light">
          <Nav className="mr-auto">
            {isLoggedIn && isSampleTest !== true && <LinkContainer to="/dashboard"><Nav.Link href="/">Dashboard</Nav.Link></LinkContainer> }
            {isLoggedIn && isSampleTest !== true && <LinkContainer to="/instructionspdf"><Nav.Link href="/">Instructions</Nav.Link></LinkContainer>}
            {isLoggedIn && !isLeapFrogTest && <LinkContainer to={currentStep}><Nav.Link href="/">Current Step</Nav.Link></LinkContainer>}
            {isLoggedIn && testUnderway !== true && isSampleTest !== true ?
              <LinkContainer to="/sampledashboard"><Nav.Link href="/">Take Sample Test</Nav.Link></LinkContainer>
            : ""
            }
            {/* TODO: LeapFrog Help Desk Link should be loaded from config, ideally */}
            {isLeapFrogTest && <a className="nav-link" href="https://leapfroghelpdesk.zendesk.com/" target="_blank" rel="noopener noreferrer" >Contact the Leapfrog Help Desk</a>}
          </Nav>
          <Nav className="ml-auto">
            {isLoggedIn && testUnderway && !isSampleTest ? <Clock /> : ''}
            {isLeapFrogTest ? '' : isLoggedIn ? 
            <LinkContainer to="/login"><Button onClick={handleLogout} className="px-3" variant="outline-light">Logout</Button></LinkContainer> :
                <Nav.Link href="mailto:Helpdesk_EHRSafetyEvalTool@utah.edu?subject=EHR Safety Eval Tool&body=Hello, I need some help with...">Need Assistance? Contact Info</Nav.Link>}
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
    );
  }

  export default Header;