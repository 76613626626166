import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContextProvider";
import "../Helpers.css";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import TestHistory from "./tools/TestHistory";
import InstructionsTable from "./tools/InstructionsTable";
import InstructionsTableLF2024 from "./tools/InstructionsTableLF2024";
import TimeoutCard from "./tools/TimeoutCard";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { failureCheckObject } from "../globals";
import useDisableBackButton from "./hooks/useDisableBackButton";
import ConfirmationModal from "./tools/ConfirmationModal";
import MOTD from "./tools/MOTD";

const Dashboard = () => {
  const {
    accessCode,
    getApiConfig,
    currentStep,
    logoutUser,
    testType,
    ttGroup,
    setCurrentStep,
    setStepNumber,
    currentTestStepNumber,
    testTimedOut,
    leapFrogDashboardUrl,
  } = useContext(UserContext);
  const [currentStepServer, setCurrentStepServer] = useState("");
  const [feedback, setFeedback] = useState("");
  const [modalShow, setModalShow] = React.useState(false);

  const history = useHistory();

  useDisableBackButton(); // Disable the browser's back button

  useEffect(() => {
    const getCurrentSiteTestStep = () => {
      // console.log('fetching current step. current step right now is ', currentStep);
      fetch(getApiConfig("getCurrentSiteTestStep"), {
        // get test step
        method: "POST",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          "access-code": accessCode,
        }),
      })
        .then((response) => response.json())
        .then((data) => {
          console.log("currentStep: ", data.response);
          // check for failure, save step in context:
          if (failureCheckObject(data)) {
            setCurrentStepServer(data.response);
          } else {
            setCurrentStepServer("NOT_STARTED");
            console.log(
              'getCurrentSiteTestStep has returned a failure; setting step to "not started".'
            );
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // setCurrentStep('NOT_STARTED');
        });
    };

    // stop fetch from happening if there's already a currentStep
    if (currentStep === "") {
      // console.log('Running getCurrentSiteTestStep, access code ->', accessCode);
      getCurrentSiteTestStep();
    }
  }, [accessCode, getApiConfig, currentStep, setCurrentStep]);

  useEffect(() => {
    // Switch statement to decide current/previous step from Steps API
    // Render buttons in the dashboard depending on test status
    // Valid steps are "NOT_STARTED", "PATIENT_PDF_REQUESTED", "ENTER_PATIENTS", "ORDER_PDF_REQUESTED",
    // "ENTER_ORDERS", "RECORD_RESPONSES", "ATTESTATION", "SCORE_RESPONSES".
    if (currentStepServer !== "")
      switch (currentStepServer) {
        case "NOT_STARTED":
          setCurrentStep("/dashboard");
          setStepNumber(0);
          break;
        case "PATIENT_PDF_REQUESTED":
          setCurrentStep("/patientspdf");
          setStepNumber(1);
          break;
        case "ENTER_PATIENTS":
          setCurrentStep("/enterpatients");
          setStepNumber(2);
          break;
        case "ORDER_PDF_REQUESTED":
          setCurrentStep("/testpdf");
          setStepNumber(3);
          break;
        case "ENTER_ORDERS":
          setCurrentStep("/enterorders");
          setStepNumber(4);
          break;
        case "RECORD_RESPONSES":
          setCurrentStep("/recordresponses");
          setStepNumber(5);
          break;
        case "ATTESTATION":
          setCurrentStep("/submitaffirmation");
          setStepNumber(6);
          break;
        case "SCORE_RESPONSES":
          setCurrentStep("/viewresults");
          setStepNumber(7);
          break;
        default:
          console.log(
            "WARNING - Current step default reached \ncurrent step is ",
            currentStepServer
          );
          // setCurrentStep('/dashboard');
          // setStepNumber(0)
          break;
      }
  }, [currentStepServer, setCurrentStep, setStepNumber]);

  // useEffect for setting feedback in 'Welcome' Card
  useEffect(() => {
    if (ttGroup === "LF") {
      if (currentTestStepNumber === 0) {
        setFeedback(
          `Once you have reviewed the CPOE Tool Instructions and the Time Limits and Steps in the table below, select the “Begin Scored & Timed Adult Inpatient Test” button to start the timed test.`
        );
      } else if (currentTestStepNumber > 0 && currentTestStepNumber < 7) {
        setFeedback(
          `This test is underway. Click 'Continue Test' to continue.`
        );
      } else if (currentTestStepNumber === 7) {
        setFeedback(
          `This test is complete. Click 'View Results' to see test results.\n CPOE Test results are available April 1 - November 30. Test results are not available while the CPOE Test is offline December 1 - March 31.`
        );
      } else {
        setFeedback(
          `Select 'Begin Scored & Timed Test' (below) to start the timed portion of the test.`
        );
      }
    } else {
      if (currentTestStepNumber === 0) {
        setFeedback(
          `Select 'Begin Scored & Timed Test' (below) to start the timed portion of the test.`
        );
      } else if (currentTestStepNumber > 0 && currentTestStepNumber < 7) {
        setFeedback(
          `This test is underway. Click 'Continue Test' to continue.`
        );
      } else if (currentTestStepNumber === 7) {
        setFeedback(
          `This test is complete. Click 'View Results' to see test results.`
        );
      } else {
        setFeedback(
          `Select 'Begin Scored & Timed Test' (below) to start the timed portion of the test.`
        );
      }
    }
  }, [currentTestStepNumber, ttGroup]);

  const handleAdvance = () => {
    setCurrentStep("/patientspdf");
    // redirect to next step
    history.push("/patientspdf");
  };

  const logOutAndReturnLater = () => {
    logoutUser();
    window.location.replace(leapFrogDashboardUrl); // update this
  };

  const buttonStyle = {
    // width: '175px',
    color: "white",
    marginTop: "1em",
    fontWeight: "300",
  };

  // previous COL size md={{ span: 10, offset: 1 }}
  return (
    <Container className="mt-5 mb-5" style={{ fontWeight: "300" }}>
      <Row>
        <Col sm={12}>
          {testTimedOut ? (
            <TimeoutCard ttGroup={ttGroup} />
          ) : (
            <Card className="mb-3">
              <Card.Header className="font-weight-bold">
                Welcome to the Adult {testType} Test
              </Card.Header>
              <Card.Body>{feedback && feedback}</Card.Body>
            </Card>
          )}
          {<TestHistory />}
          {currentTestStepNumber < 7 && ttGroup === "LF" ? (
            <InstructionsTableLF2024 />
          ) : (
            ""
          )}
          {currentTestStepNumber < 7 && ttGroup !== "LF" ? (
            <InstructionsTable testType={testType} />
          ) : (
            ""
          )}
        </Col>
      </Row>
      <MOTD />
      <Row>
        <Col sm={12} style={{ textAlign: "right" }}>
          {currentTestStepNumber < 7 ? (
            <Button
              className="text-nowrap"
              style={{ marginTop: "1em", fontWeight: "300" }}
              onClick={logOutAndReturnLater}
              variant="warning"
            >
              Logout &#38; Return Later
            </Button>
          ) : (
            ""
          )}{" "}
          <ConfirmationModal
            show={modalShow}
            onConfirm={handleAdvance}
            onHide={() => setModalShow(false)}
            title="Begin Scored & Timed Test"
            text={
              <>
                <p>
                  Are you sure you want to start the Adult {testType} Test that
                  is scored and timed?
                </p>
                <p>
                  If you meant to take the Sample Test, click 'Close' to return
                  to the CPOE Dashboard.
                </p>
              </>
            }
          />
          {currentStep === "/dashboard" || currentStep === "" ? (
            <Button
              variant="success"
              style={{ marginTop: "1em", fontWeight: "300" }}
              className="text-nowrap"
              onClick={() => setModalShow(true)}
            >
              Begin{" "}
              <em>
                <strong>Scored & Timed</strong>
              </em>{" "}
              Adult {testType} Test
            </Button>
          ) : currentStep === "/viewresults" || currentTestStepNumber === 7 ? (
            <LinkContainer to={currentStep} style={buttonStyle}>
              <Button variant="success">View Results</Button>
            </LinkContainer>
          ) : (
            <LinkContainer to={currentStep} style={buttonStyle}>
              <Button variant="info">Continue Test</Button>
            </LinkContainer>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Dashboard;
